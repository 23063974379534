import React from 'react'
import Link from '../components/Link'
import Layout from '../components/Layout'
import Header from '../components/Header'
import BlogEntries from '../components/BlogEntries'
import { strengthList } from '../assets/lib/constants'
import Floating from '../components/FloatingBanner'

const IndexPage = () => {
  return (
    <Layout>
      <Header isTopPage />
      <Floating />
      <div className="is_main main">
        <div className="catchphrase">
          <div className="catchphrase__inner">
            <div className="catchphrase__area clearfix">
              <p className="catchphrase__text">理想的な働き方</p>
              <p className="catchphrase__x"><img src="image/top/img_x.svg" alt="" /></p>
              <p className="catchphrase__text">圧倒的開発環境</p>
            </div>
            <p className="catchphrase__body">高品質、適正価格なシステムを短納期で納品</p>
          </div>
        </div>
        <section className="merit">
          <div className="merit__inner">
            <ul className="merit__list">
              {strengthList.map((strength) => (
                <li className={`merit__list-item merit__list-item--${strength.key}`} key={strength.key}>
                  <div className="merit__icon">
                    <img src={`image/top/ico_${strength.key}.svg`} alt="" />
                  </div>
                  <div className="merit__text">
                    <p className="merit__title">{strength.title}</p>
                    <p className="merit__body">{strength.body}</p>
                  </div>
                  <div className="merit__link-area">
                    <Link
                      to={`/strengths/#${strength.key}`}
                      className="merit__link"
                    >
                      詳しく見る
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
    
        <section className="section section--service">
          <div className="section__inner section__inner--small">
            <div className="sec-title">
              <h2 className="sec-title__en">SERVICES</h2>
              <p className="sec-title__jp">事業内容</p>
            </div>
            <div className="service">
              <div className="service__content clearfix">
                <div className="service__vis service__vis--left">
                  <img src="image/top/img_develop.jpg" alt="" className="service__img" />
                  <img src="image/top/img_dot_blue.png" alt="" className="service__dot service__dot--left" />
                </div>
                <div className="service__text service__text--right">
                  <div className="service__title service__title--right">受託開発</div>
                  <img src="image/top/img_border_blue.png" alt="" className="service__border" />
                  <p className="service__body service__body--right">Webサービス、スマホアプリ、大規模データ処理などで豊富な実績があります。</p>
                </div>
              </div>
              <div className="service__content service__content--smb clearfix">
                <div className="service__vis service__vis--right">
                  <img src="image/top/img_smb.jpg" alt="" className="service__img" />
                  <img src="image/top/img_dot_pink.png" alt="" className="service__dot service__dot--right" />
                </div>
                <div className="service__text service__text--left">
                  <div className="service__title service__title--left">中小企業向けIT化支援・SI</div>
                  <img src="image/top/img_border_pink.png" alt="" className="service__border" />
                  <p className="service__body service__body--left">最近は、ユーザー企業自らIT・システム化を行えるツール・環境が増えています。弊社では、それらを活用した中小企業のIT化を支援します。</p>
                </div>
              </div>
              <div className="service__content service__content--web clearfix">
                <div className="service__vis service__vis--left">
                  <img src="image/top/img_webservice.jpg" alt="" className="service__img" />
                  <img src="image/top/img_dot_purple.png" alt="" className="service__dot service__dot--left" />
                </div>
                <div className="service__text service__text--right">
                  <div className="service__title service__title--right">Webサービス</div>
                  <img src="image/top/img_border_purple.png" alt="" className="service__border" />
                  <p className="service__body service__body--right">弊社の開発効率を支える社内システムを、徐々に外部向けWebサービスとして提供していきます。</p>
                </div>
              </div>
              <div className="service__content service__content--education clearfix">
                <div className="service__vis service__vis--right">
                  <img src="image/top/img_education.jpg" alt="" className="service__img" />
                  <img src="image/top/img_dot_green.png" alt="" className="service__dot service__dot--right" />
                </div>
                <div className="service__text service__text--left">
                  <div className="service__title service__title--left">教育関連サービス</div>
                  <img src="image/top/img_border_green.png" alt="" className="service__border" />
                  <p className="service__body service__body--left">日本人エンジニアが、短期間で英語でのプロジェクトを進めていけるようになる英語研修、などを行っています。</p>
                </div>
              </div>
            </div>
            <div className="btn-area">
              <a className="button" href="/services/">事業内容を詳しく見る</a>
            </div>
          </div>
          <div className="service__bg service__bg--laptop">
            <img src="image/top/img_bg_laptop.png" alt="" className="service__bg-img" />
          </div>
          <div className="service__bg service__bg--mobile">
            <img src="image/top/img_bg_mobile.png" alt="" className="service__bg-img" />
          </div>
          <div className="service__bg service__bg--mouse">
            <img src="image/top/img_bg_mouse.png" alt="" className="service__bg-img" />
          </div>
        </section>
    
        <section className="section section--gray">
          <div className="section__inner">
            <div className="sec-title">
              <h2 className="sec-title__en">WORKS</h2>
              <p className="sec-title__jp">実績紹介</p>
            </div>
            <div className="work work--with-mb">
              <ul className="work__list">
                <li className="work__list-item">
                  <Link to="/works/#senkyocom" className="work__link">
                    <div className="work__ss">
                      <img src="image/top/works-top_senkyo.jpg" className="work__image-top" alt="" />
                    </div>
                    <div className="work__text">
                      <p className="work__name">選挙ドットコム / ボネクタ</p>
                      <p className="work__company">イチニ株式会社様</p>
                      <p className="work__tag work__tag--develop">受託開発</p>
                    </div>
                  </Link>
                </li>
                <li className="work__list-item">
                  <Link to="/works/#ad-delivery" className="work__link">
                    <div className="work__ss">
                      <img src="image/top/works-top_shibuya.jpg" className="work__image-top" alt="" />
                    </div>
                    <div className="work__text">
                      <p className="work__name">広告配信システム</p>
                      <p className="work__company">某広告会社様</p>
                      <p className="work__tag work__tag--data_ml">データ分析・機械学習</p>
                    </div>
                  </Link>
                </li>
                <li className="work__list-item">
                  <Link to="/works/#video-chat" className="work__link">
                    <div className="work__ss">
                      <img src="image/top/works-top_video-chat.jpg" className="work__image-top" alt="" />
                    </div>
                    <div className="work__text">
                      <p className="work__name">有料ビデオチャットサービス</p>
                      <p className="work__company">某Webサービス企業様</p>
                      <p className="work__tag work__tag--develop">受託開発</p>
                    </div>
                  </Link>
                </li>
                <li className="work__list-item">
                  <Link to="/works/#versionup-amazonpay" className="work__link">
                    <div className="work__ss">
                      <img src="image/top/works-top_diskunion.png" className="work__image-top" alt="" />
                    </div>
                    <div className="work__text">
                      <p className="work__name">Amazon Payのバージョンアップ</p>
                      <p className="work__company">株式会社ディスクユニオン様</p>
                      <p className="work__tag work__tag--web">Webサービス</p>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="btn-area">
              <a className="button" href="/works/">実績を詳しく見る</a>
            </div>
          </div>
        </section>
    
        <section className="section">
          <div className="section__inner section__inner--small">
            <div className="sec-title">
              <h2 className="sec-title__en">NEWS</h2>
              <p className="sec-title__jp">最新情報</p>
            </div>
            <BlogEntries />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
