import React, { useState, useEffect } from 'react'
import axios from 'axios'

const BlogEntries = () => {
  const URL = '//blog.mobalab.net/wp-json/wp/v2/posts'

  const [posts, setPosts] = useState([])

  useEffect(() => {
    const fetchBlogEntires = async () => {
      try {
        const result = await axios.get(URL)
        setPosts(result.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchBlogEntires()
  }, [])

  return (
    <div className="news">
      <ul className="news__list js-blog-entries">
        {posts.map((post, index) => {
          const pubDate = new Date(post.date);
          const monthAgo = new Date()
          monthAgo.setDate(monthAgo.getDate() - 30);
          const isNew = pubDate > monthAgo
          const pubYear = pubDate.getFullYear()
          const pubMonth = pubDate.getMonth() + 1
          const pubDay = pubDate.getDate()
          const pubDateString = `${pubYear}年${pubMonth}月${pubDay}日`

          return(
            <li className={`news__list-item${isNew ? ' news__list-item--new': ''}`} key={index}>
              <article>
                <a className="btn-news" href={post.link} target="_blank" rel="noreferrer">
                  <p className="news__title">{escapeHtml(post.title.rendered)}</p>
                  <p className="news__date">{pubDateString}</p>
                </a>
              </article>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const escapeHtml = (text) => {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };

  return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

export default BlogEntries
