import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const FloatingBanner = () => {
    useEffect(() => {
        const bannerClose = document.createElement('script');
        bannerClose.src = "https://code.jquery.com/jquery-3.6.1.js";
        bannerClose.integrity = "sha256-3zlB5s2uwoUzrXK3BT7AX3FyvojsraNFxCc2vC/7pNI=";
        bannerClose.crossOrigin = "anonymous";
        bannerClose.onload = () => {
          $(function () {
            $(document).ready(function () {
              $('.floating__close').click(function() {
                $(this).parent().hide();
              });
            });
          });
        };
        document.head.appendChild(bannerClose);
      }, [])

  return (
    <div>
        <Helmet>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css" 
            integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A==" crossorigin="anonymous" referrerpolicy="no-referrer" />
        </Helmet>
        <div className="floating">
            <button className="floating__close">×</button>
            <a className="floating__link" href="https://blog.mobalab.net/document-download-form/" target="_blank">
                <div className="floating__back">
                    <div className="floating__body">
                        <div className="floating__top">
                            <i className="fas fa-envelope floating__icon"></i>
                        </div>
                        <div className="floating__bottom">
                            <p className="floating__copy">ホワイトペーパーを受け取る</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
  )
}

export default FloatingBanner;
